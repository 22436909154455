import * as Rx from 'rxjs/Rx';
import { Event } from "./event";


class Base {
    private bus: Rx.Subject<Event> = new Rx.Subject<Event>();

    post(t: Event) {
        if (this.bus.observers.length > 0) {
            this.bus.next(t);
        }
    }

    toObservable(): Rx.Observable<Event> {
        return this.bus.asObservable();
    }
}

export const EventBus = new Base();
