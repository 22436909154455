import * as React from 'react';
import { sendGAEvent } from 'app/util';

const styles = require('./index.pcss');

export const InfoHeader = () => {
    const onClick = () => sendGAEvent('공동인증서복사하기', '상단');

    return (
        <header className={ styles.wrap }>
            <ul className={ styles.list }>
            <li className={ styles.bi }><h1>BANKSALAD</h1></li>
            <li className={ styles.link }>
                <a
                    href="#certificate"
                    rel="noreferrer"
                    onClick={ onClick }
                >
                    <h2>공동인증서 복사하기</h2>
                </a>
            </li>
            </ul>
        </header>
    );
};
