import * as React from 'react';
import * as Bowser from 'bowser';

const s = require('./index.pcss');

export class CertificateDialog extends React.Component {
    private isDesktopSafari = () => {
        const browser = Bowser.getParser(window.navigator.userAgent);

        return browser.getBrowserName() === 'Safari' && browser.getPlatformType() === 'desktop';
    };

    private renderInformationForSafariUser = () => {
        const OUTBOUND_LINK_FOR_DOWNLOAD_CHROME = 'https://www.google.com/chrome';

        return (
            <div className={ s.informationWrap }>
                <div className={ s.information }>
                    <h3 className={ s.title }>Chrome에서 서비스를 이용해주세요</h3>
                    <p className={ s.description }>
                        Safari의 정책으로 인해  공동인증서 플러그인이 동작하지 않습니다.<br/>
                        Chrome으로 접속하시면 서비스를 원활하게 이용하실 수 있습니다.
                    </p>
                    <a
                      href={OUTBOUND_LINK_FOR_DOWNLOAD_CHROME}
                      target="_blank"
                      rel="nofollow"
                      className={ s.link }
                    >
                        Chrome 설치하기
                    </a>
                </div>
            </div>
        );
    };

    private renderCertModule = () => (
      <iframe src="/nxCR/index.html" />
    );

    render() {
        return (
            <div className={ s.wrap }>
                {
                    this.isDesktopSafari() ?
                        this.renderInformationForSafariUser() :
                        this.renderCertModule()
                }
            </div>
        )
    }
}
