import * as React from 'react';
import { Utils } from 'presentation/utils';
import { CertificateDialog } from 'presentation/components/dialog/certificate';
import { sendGAEvent } from 'app/util';

const styles = require('./index.pcss');

export const CertificateInfo = () => {
    const onClickCertificate = () => {
        sendGAEvent('공동인증서복사하기', '하단');
        Utils.alert("공동인증서 복사하기", <CertificateDialog />);
    };

    return (
        <section className={ styles.wrap }>
            <h2 className={ styles.title }>PC → 앱으로<br/> 인증서 복사하기</h2>
            <button
                className={ styles.submit }
                onClick={ onClickCertificate }
            >
                복사하기
            </button>
            <ul className={ styles.list }>
            <li className={ styles.item }>
                <span className={ styles.order }>step1</span>
                <p className={ styles.desc }>
                    위 [복사하기] 버튼을 클릭하여<br/> 인증서 복사 프로그램을 실행합니다
                </p>
            </li>
            <li className={ styles.item }>
                <span className={ styles.order }>step2</span>
                <p className={ styles.desc }>
                    인증서 복사 프로그램에<br/>인증서 비밀번호를 입력합니다
                </p>
            </li>
            <li className={ styles.item }>
                <span className={ styles.order }>step3</span>
                <p className={ styles.desc }>
                    뱅크샐러드 앱에 표시된<br/>인증번호 12자리를 입력합니다
                </p>
            </li>
            </ul>
        </section>
    );
};
