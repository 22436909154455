import { Subscription } from 'rxjs';

declare module "rxjs/Subscription" {
    export interface Subscription {
        unsubscribeBy(bag: SubscriptionBag): void;
    }
}

Subscription.prototype.unsubscribeBy = function(bag: SubscriptionBag) {
    bag.push(this);
};

export class SubscriptionBag {
    private subscriptions: Array<Subscription> = [];

    push(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }

    destroy() {
        this.subscriptions.forEach(it => it.unsubscribe());
    }
}
