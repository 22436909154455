import * as React from 'react';

import { EventBus } from 'bus/bus';
import { AlertEvent } from 'bus/events/alertEvent';


class Base {
    alert = (title: String, component: React.ReactChild) => {
        EventBus.post(new AlertEvent(title, component));
    }
}

export const Utils = new Base();
