export const LINKS = {
    HERO_AOS: 'https://go.onelink.me/app/73e39c0a',
    HERO_IOS: 'https://go.onelink.me/app/64233b34',
    FOOTER_AOS: 'https://go.onelink.me/app/fb44514',
    FOOTER_IOS: 'https://go.onelink.me/app/4aef71e6',
    FOOTER_MAIL_INFO: 'mailto:hello@banksalad.com',
    FOOTER_NOTICE: 'https://banksalad.com/notice',
    FOOTER_AUTH_WORK_RULE: 'https://policies.banksalad.com/banksaladcertificate/cps',
    FOOTER_MYDATA_CERTIFICATE_TERMS: 'https://policies.banksalad.com/banksaladcertificate/뱅크샐러드-인증서-이용약관',
    FOOTER_MYDATA_CERTIFICATE_PRIVACY_POLICY: 'https://policies.banksalad.com/banksaladcertificate/뱅크샐러드-인증서-개인정보처리방침',
};
