import * as React from 'react';

const styles = require('./index.pcss');

export const DetailImages = () => {
    return (
        <section className={ styles.wrap }>
            <img
                src="/resources/image/detail_pc.png"
                alt="뱅크샐러드 소개 이미지"
                className={ styles.pcImage }
            />
            <img
                src="/resources/image/detail_tablet.png"
                alt="뱅크샐러드 tablet 소개 이미지"
                className={ styles.tabletImage }
            />
            <img
                src="/resources/image/detail_mw.png"
                alt="뱅크샐러드 Mobile 소개 이미지"
                className={ styles.mobileImage }
            />
        </section>
    )
};
