import * as React from 'react';
import { sendGAEvent } from 'app/util';

const styles = require('./index.pcss');

export const MyData = () => {
    const onClickLink = (
        title: string,
        link: string
    ) => () => (
        sendGAEvent('PR기사', title, link)
    );

    return (
        <section className={ styles.wrap }>
            <h2 className={ styles.title }>정부의 마이데이터 사업,<br/>핵심 시범 기업 선정</h2>
            <ul className={ styles.newsList }>
            <li className={ styles.newsItem }>
                <a
                    href="http://news.wowtv.co.kr/NewsCenter/News/Read?articleId=A201808310370&t=NN"
                    rel="noreferrer"
                    target="_blank"
                    onClick={ onClickLink('뱅크샐러드, 데이터경제 간담회 핀테크 대표 참석', 'http://news.wowtv.co.kr/NewsCenter/News/Read?articleId=A201808310370&t=NN') }
                >
                    <strong className={ styles.newsTitle }>뱅크샐러드, 데이터경제 간담회 핀테크 대표 참석</strong>
                    <ul className={ styles.infoList }>
                    <li className={ styles.companyTitle }>한국경제TV</li>
                    <li className={ styles.date }>2018.08.31</li>
                    </ul>
                </a>
            </li>
            <li className={ styles.newsItem }>
                <a
                    href="http://www.businesspost.co.kr/BP?command=article_view&num=99488"
                    rel="noreferrer"
                    target="_blank"
                    onClick={ onClickLink('핀테크 스타트업 레이니스트, ‘데이터 이동권’으로 성장의 날개 달다', 'http://www.businesspost.co.kr/BP?command=article_view&num=99488') }
                >
                    <strong className={ styles.newsTitle }>핀테크 스타트업 레이니스트, ‘데이터 이동권’으로 성장의 날개 달다</strong>
                    <ul className={ styles.infoList }>
                    <li className={ styles.companyTitle }>BUSINESS POST</li>
                    <li className={ styles.date }>2018.10.21</li>
                    </ul>
                </a>
            </li>
            <li className={ styles.newsItem }>
                <a
                    href="http://www.newsis.com/view/?id=NISI20180831_0014422099"
                    rel="noreferrer"
                    target="_blank"
                    onClick={ onClickLink('데이터 규제혁신 현장 방문한 문재인 대통령', 'http://www.newsis.com/view/?id=NISI20180831_0014422099') }
                >
                    <strong className={ styles.newsTitle }>데이터 규제혁신 현장 방문한 문재인 대통령</strong>
                    <ul className={ styles.infoList }>
                    <li className={ styles.companyTitle }>NEWSIS</li>
                    <li className={ styles.date }>18.08.31</li>
                    </ul>
                </a>
            </li>
            </ul>
        </section>
    );
};
