import * as React from 'react';
import { HelpContents } from 'presentation/components/help/constants';
import { sendGAEvent } from 'app/util';

const styles = require('./index.pcss');

export const Help = () => {
    const onClickLink = (
        title: string
    ) => () => (
        sendGAEvent(title, '도움말')
    );

    const renderList = () => (
        <ul className={ styles.list }>
        {
            HelpContents.map((content, i) =>
                <li key={ i } className={ styles.item }>
                    <label
                        htmlFor={ `${i}` }
                        className={ styles.itemTitle }
                        onClick={ onClickLink(content.title) }
                    >
                        <h3>{ content.title }</h3>
                    </label>
                    <input id={ `${i}` } type="checkbox" className={ styles.check }/>
                    <p className={ styles.itemDesc } dangerouslySetInnerHTML={{ __html: content.desc }}/>
                </li>
            )
        }
        </ul>
    );

    return (
        <section className={ styles.wrap }>
            <h2 className={ styles.title }>도움말</h2>
            { renderList() }
        </section>
    );
};
