import * as React from 'react';
import { SubscriptionBag } from 'presentation/module/extension'
import { EventBus } from 'bus/bus';
import { DocumentScrollEvent } from 'bus/events/document-scroll-event';

const styles = require('./styles.pcss');
const MIN_VISIBILITY_THRESHOLD = 380;

interface Props {
    title: string;
    issueUrl: string;
    eventFunction: any;
}

interface State {
    isVisibility: boolean;
}

export class EventIssueButton extends React.Component<Props, State> {
    state = {
        isVisibility: false
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        EventBus.toObservable()
            .subscribe(
                (event: Event) => {
                    if (event instanceof DocumentScrollEvent) {
                        this.fetchNextPageOnDocumentScroll(event.scrollTop);
                    }
                }
            )
            .unsubscribeBy(this.subscriptionBag);
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { title, issueUrl } = this.props;

        return (
            <div className={ styles.wrap }>
                <a
                    href={ issueUrl }
                    className={ this.state.isVisibility ? styles.issueButton : styles.hidingIssueButton}
                    onClick={ this.props.eventFunction }
                    target='_blank'
                >
                    { title }
                </a>
            </div>
        )
    }

    private fetchNextPageOnDocumentScroll = (scrollTop: number) => {
        const visible = scrollTop > MIN_VISIBILITY_THRESHOLD;
        this.setState({
            isVisibility: visible
        });
    };
}


