import { initSentry } from 'app/util';
import { Certificate } from 'presentation/certificate';
import { Container } from 'presentation/container';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

initSentry();

ReactDOM.render(
  <BrowserRouter>
    <Container>
      <Switch>
        <Route component={Certificate} />
      </Switch>
    </Container>
  </BrowserRouter>,
  document.getElementById('wrap')
);
