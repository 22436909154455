import * as React from 'react';
import { isMobile, sendGAEvent } from 'app/util';
import { LINKS } from 'presentation/constants';

const styles = require('./index.pcss');

export const Footer = () => {
  const onClickLink = (
    action: string,
    category?: string
  ) => () => (
    sendGAEvent(action, category)
  );

  return (
    <footer className={ styles.wrap }>
      <h2 className={ styles.title }>banksalad</h2>
      <ul className={ styles.stores }>
      <li className={ styles.store }>
        <a
          href={ LINKS.FOOTER_AOS }
          target="_blank"
          rel="noreferrer"
          className={ isMobile() ? styles.google : styles.desktopGoogle }
          onClick={ onClickLink('구글플레이스토어', '하단') }
        >
          플레이스토어
        </a>
      </li>
      <li className={ styles.store }>
        <a
          href={ LINKS.FOOTER_IOS }
          target="_blank"
          rel="noreferrer"
          className={ isMobile() ? styles.apple : styles.desktopApple }
          onClick={ onClickLink('앱스토어', '하단') }
        >
          앱스토어
        </a>
      </li>
      </ul>
        <div className={ styles.linkContainer }>
          <ul className={ styles.links }>
          <li className={ styles.link }>
            <a
              href={ LINKS.FOOTER_NOTICE }
              className={ styles.linkText }
              onClick={ onClickLink('공지사항') }
            >
              공지사항
            </a>
          </li>
          <li className={ styles.link }>
            <a
              href={ LINKS.FOOTER_AUTH_WORK_RULE }
              className={ styles.linkText }
              onClick={ onClickLink('인증업무준칙') }
            >
              인증업무준칙
            </a>
          </li>
          <li className={ styles.link }>
            <a
              href={ LINKS.FOOTER_MYDATA_CERTIFICATE_TERMS }
              className={ styles.linkText }
              onClick={ onClickLink('인증서 이용약관') }
            >
              인증서 이용약관
            </a>
          </li>
          <li className={ styles.link }>
            <a
              href={ LINKS.FOOTER_MYDATA_CERTIFICATE_PRIVACY_POLICY }
              className={ styles.mydataCertificatePrivacyPolicyLinkText }
              onClick={ onClickLink('인증서 개인정보처리방침') }
            >
              인증서 개인정보처리방침
            </a>
          </li>
        </ul>
      </div>

      <div className={ styles.company }>
        <strong>(주)뱅크샐러드</strong> 대표: 김태훈 사업자등록번호: 105-87-73978   통신판매업신고: 2023-서울마포-2465<br/>
        <a
          href={ LINKS.FOOTER_MAIL_INFO }
          rel="noreferrer"
        >
          E-mail: hello@banksalad.com
        </a> <br className={styles.newLine }/>주소: 서울특별시 마포구 마포대로 45, 일진빌딩 10층
      </div>
      <div className={ styles.copyright }>
        © Banksalad Co., Ltd. All rights reserved.
      </div>
    </footer>
  );
};
