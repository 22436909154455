import * as React from 'react';

const styles = require('./index.pcss');

interface Props {
    title: String,
    onCancel(): void
}

export class DialogBase extends React.Component<Props> {
    render() {
        return (
            <div className={ styles['wrap'] }>
                <div
                    className={ styles['dim'] }
                    onClick={ () => this.props.onCancel() }
                />

                <div className={ styles['dialog'] } onClick={ null }>
                    <h4 className={ styles['title'] }>{ this.props.title }</h4>
                    <a
                        role="button"
                        className={ styles['cancel'] }
                        onClick={ () => this.props.onCancel() }
                    >
                        확인
                    </a>
                    <div className={ styles['content'] }>
                        { this.props.children }
                    </div>
                </div>
            </div>
        )
    }
}
