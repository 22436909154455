import * as React from 'react';

const styles = require('./index.pcss');

export class Status extends React.Component {
    render() {
        return (
            <section className={ styles.wrap }>
                <ul className={ styles.list }>
                <li className={ styles.item }>
                    <strong className={ styles.term }>누적 다운로드</strong>
                    <figure className={ styles.figure }>1000만</figure>
                </li>
                <li className={ styles.item }>
                    <strong className={ styles.term }>연동 관리 금액</strong>
                    <figure className={ styles.figure }>410조원</figure>
                </li>
                <li className={ styles.item }>
                    <strong className={ styles.term }>보안사고</strong>
                    <figure className={ styles.figure }>0건</figure>
                </li>
                </ul>
            </section>
        )
    }
}
