import * as React from 'react';
import { InfoHeader } from 'presentation/components/info-header';
import { InfoCover } from 'presentation/components/info-cover';
import { CertificateInfo } from 'presentation/components/certificate-info';
import { DetailInfo } from 'presentation/components/detail-info';
import { Help } from 'presentation/components/help';
import { Footer } from 'presentation/components/footer';
import { Status } from 'presentation/components/status';
import { MyData } from 'presentation/components/my-data';
import { DetailImages } from 'presentation/components/detail-images';
import { ACTION, sendGAEvent } from 'app/util';
import { EventIssueButton } from 'presentation/components/floating-button';

const styles = require('./index.pcss');
const commonStyles = require('../layout.pcss');
const onClickStore = (
    action: string
) => () => sendGAEvent(
    action
);

export class Certificate extends React.Component {
    state = {
        isVisibility: false
    };

    render() {
        return (
            <div className={ styles.wrap }>
                <section className={ styles.info }>
                    <div className={ commonStyles.container }>
                        <InfoHeader/>
                        <InfoCover/>
                    </div>
                </section>
                <section id="certificate" className={ styles.certificate }>
                    <div className={ commonStyles.container }>
                        <CertificateInfo/>
                    </div>
                </section>
                <section id="status" className={ styles.status }>
                    <div className={ commonStyles.container }>
                        <Status/>
                    </div>
                </section>
                <section id="detail" className={ styles.detail }>
                    <div className={ commonStyles.container }>
                        <DetailInfo/>
                    </div>
                </section>
                <section id="detail-images">
                    <div className={ commonStyles.detailImageContainer }>
                        <DetailImages/>
                    </div>
                </section>
                <section id="my-data" className={ styles.myData }>
                    <div className={ commonStyles.container }>
                        <MyData/>
                    </div>
                </section>
                <section id="help" className={ styles.help }>
                    <div className={ commonStyles.container }>
                        <Help/>
                    </div>
                </section>
                <section className={ styles.footer }>
                    <div className={ commonStyles.container }>
                        <Footer/>
                    </div>
                </section>
                <div className={ styles.eventButton }>
                    <EventIssueButton
                        title={'뱅크샐러드 앱 다운로드'}
                        issueUrl='https://go.onelink.me/xMM7/f4749686'
                        eventFunction={onClickStore(ACTION.ONELINK)}
                    />
                </div>
            </div>
        )
    }
}
