import * as React from 'react';
import { Event } from '../event';


export class KeyDownEvent implements Event {
    keyCode: Number = 0;

    constructor(keyCode: Number) {
        this.keyCode = keyCode;
    }
}
