import * as React from 'react';
import { ACTION, sendGAEvent } from 'app/util';

const styles = require('./index.pcss');

export const InfoCover = () => {
  const onClickStore = (action: string) => () => sendGAEvent(action, '상단');

  return (
    <section className={styles.wrap}>
      <div className={styles.videoWrap}>
        <video autoPlay loop muted playsInline preload="none" className={styles.video}>
          <source src="/resources/image/video.mp4" type="video/mp4" />
        </video>
        <div className={styles.frame} />
      </div>
      <div className={styles.infoWrap}>
        <div className={styles.infoContents}>
          <div className={styles.title}>
            <h1>금융을 내 편으로</h1>
            <h2>뱅크샐러드</h2>
            <p>지금 시작하세요</p>
          </div>
          <a
            href="https://go.onelink.me/xMM7/f4749686"
            title="앱 다운로드"
            target="_blank"
            onClick={onClickStore(ACTION.ONELINK)}
            className={styles.buttons}
          >
            앱 다운로드
          </a>
        </div>
      </div>
    </section>
  );
};
