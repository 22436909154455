import * as React from 'react';
import { sendGAEvent } from 'app/util';

const styles = require('./index.pcss');

export const DetailInfo = () => {
  const onClickButton = () =>
    sendGAEvent('보안정책 알아보기', null, 'https://help.banksalad.com/고객안심센터');

  return (
    <section className={styles.wrap}>
      <h2 className={styles.title}>가장 혁신적인 금융앱</h2>
      <ul className={styles.list}>
        <li className={styles.item}>∙ 앱스토어 무료 앱 전체 1위, 금융부문 1위</li>
        <li className={styles.item}>∙ 구글플레이 선정 ‘올해의 가장 혁신적인 앱’ 수상</li>
        <li className={styles.item}>∙ 실제 사용자들이 극찬하는 앱 서비스!</li>
        <li className={styles.item}>∙ 미국 은행들도 사용하는 암호화 기술로 보안걱정 NO!</li>
      </ul>
      <a
        href="https://help.banksalad.com/고객안심센터"
        target="_blank"
        className={styles.link}
        onClick={onClickButton}
      >
        보안정책 알아보기
      </a>
    </section>
  );
};
