import { List } from 'immutable';

export class HelpContent {
    title: string;
    desc: string;

    constructor(
        title: string,
        desc: string
    ) {
        this.title = title;
        this.desc = desc;
    }
}

export const HelpContents: List<HelpContent> = List.of(
    new HelpContent(
        '공동인증서를 왜 등록해야 하나요?',
        '뱅크샐러드는 흩어진 금융정보를 한데 모아서 지출과 자산을 자동으로 관리해 주는 서비스입니다.<br/><br/>금융기관과 연동하여 데이터를 자동으로 불러오기 위해 최초 1회에 한해 공동인증서 등록이 필요합니다.<br/><br/>공동인증서는 저희 서버에 저장되지 않으니 안심하셔도 좋습니다.'
    ),
    new HelpContent(
        '모든 은행과 카드사 조회가 가능한가요?',
        `현재 연동 가능한 은행은 19개, 카드사는 10개입니다.<br/><br/>은행은 제 2금융권을 포함하여 계속 추가될 예정이니 조금만 기다려주세요.<br/><br/>⊙은행 - 국민, 신한, 농협, KEB하나, SC, 우리, 새마을금고, 대구, 부산, 산업, 수협, 경남, 신협, 씨티, 광주, 전북, 기업, 제주, 우체국<br/><br/>⊙카드 - 국민, 농협, 롯데, 삼성, 신한, 씨티, 우리, 현대, 비씨, 하나(외환)`
    ),
    new HelpContent(
        '보안이 불안합니다. 안심하고 사용해도 되나요?',
        `뱅크샐러드의 보안은 미국 은행에서 사용하고 있는 암호화 방식을 이용해, 가장 안전한 수준으로 설계되었습니다.<br/><br/>개인 금융내역 등 민감한 개인정보를 다루고 있는 만큼, 속도 및 편의성을 일부 희생하더라도 보다 완벽한 보안 체계를 갖추는 데 주력했습니다.<br/><br/>공동인증서는 뱅크샐러드 서버에 저장되지 않습니다.<br/><br/>또한 로그인에 필요한 암호 등 정보는 미국 은행에서 사용하고 있는 AES256 암호화 방식을 사용하며, 여기에 복호화가 불가능한 단방향 암호를 통해 이중으로 보호됩니다.<br/><br/>저희는 물론, 그 누구도 사용자 암호를 물리적으로 알아낼 수 없는 구조의 보안 체계입니다.<br/><br/>구글이나 애플 등 글로벌 기업에서 사용자 계정 정보를 저장할 때 이러한 방식을 취하고 있습니다.`
    ),
    new HelpContent(
        '공동인증서가 여러 개 뜨는데, 어떤 걸 등록해야 하나요?',
        `가장 최근에 발급받은 공동인증서를 등록하시면 됩니다.<br/><br/>선택한 공동인증서가 금융기관에 미리 등록되어 있어야 해당 금융기관과 금융정보 연동이 가능한 점 참고 부탁 드립니다.<br/><br/>※ 은행/신용카드/보험용 공동인증서(무료) 또는 범용 공동인증서(유료)로 연동하실 수 있으며, 증권/보험용 공동인증서는 연동이 불가능합니다.`
    ),
    new HelpContent(
        '공동인증서가 없어요. 어떻게 해야 하나요?',
        `뱅크샐러드를 사용하시기 위해서는 공동인증서가 꼭 필요합니다.<br/><br/>공동인증서는 주로 거래하시는 은행의 홈페이지 또는 지점에서 무료로 즉시 발급받으실 수 있습니다.<br/><br/>※ 일반적으로 많이 사용하는 [은행/신용카드/보험용]으로 발급시 무료이며, 개인 범용 인증서는 연간 4,400원의 수수료가 발생합니다.`
    ),
    new HelpContent(
        '맥 OS에서 공동인증서 복사프로그램이 작동되지 않습니다.',
        `Safari 버전이 12.0 이상이실 경우 Safari의 플러그인 차단 정책으로 공동인증서 복사 프로그램이 정상적으로 작동하지 않을 수 있습니다.<br/><br/>이 경우에는  Chrome으로 접속해주시면 원활히 이용하실 수 있습니다.<br/><br/>[Safari 버전 확인 방법 : 화면 맨 상단 왼쪽 Safari 로고 - Safari에 관하여 - 버전이 12.0 이상인지 확인]<br/><br/>만약, Safari 버전이 12.0 이상이 아닌데도 복사가 진행되지 않는다면 아래처럼 설정을 변경하시고 다시 시도해주세요.<br/><br/>1. Safari 환경설정 > 웹 사이트 > ‘touchenex CrossEX N…’ 체크버튼 선택<br/><br/>2. 오른쪽 app.banksalard.com 박스를  “켬”으로 변경<br/><br/>3.  환경설정 창 종료 후 app.banksalad.com 페이지 새로고침 버튼 클릭<br/><br/>4. ‘공동인증서 복사하기’ 재시도<br/><br/><div style="text-align: center;"><img width="80%" height="100%" src="https://cdn.banksalad.com/entities/etc/1541051725632-image%20%2810%29%20%282%29.png"/></div>`
    )
);
