import * as Sentry from '@sentry/react';

export const isMobile = () => !!(
    navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
);

export const sendGAEvent = (
    action: string,
    category: string = null,
    label: string = null,
    value: string = null
) => gtag(
    'event',
    action, {
        'event_category': category,
        'event_label': label,
        'value': value
    }
);

export enum ACTION {
    ONELINK = 'onelink'
}

export const initSentry = () => Sentry.init({
  dsn: 'https://ef2a431d4cc84b34a3fc3f19b1b9c109@o26606.ingest.sentry.io/5589268',
  denyUrls: [
    /staging\.banksalad\.com/i
  ]
});
