import * as React from 'react';
import { Event } from '../event';


export class AlertEvent implements Event {
    title: String = "";
    component?: React.ReactChild = null;

    constructor(title: String, component: React.ReactChild) {
        this.title = title;
        this.component = component;
    }
}
