import { EventBus } from 'bus/bus';
import { AlertEvent } from 'bus/events/alertEvent';
import { KeyDownEvent } from 'bus/events/keyDownEvent';
import { DialogBase } from 'presentation/components/dialog';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Subscription } from 'rxjs/Rx';
import './normalize.pcss';
import { DocumentScrollEvent } from 'bus/events/document-scroll-event';

const commonStyles = require('./layout.pcss');

interface Props extends RouteComponentProps<any> {
    /* explicitly empty */
}

interface State {
    dialog: Boolean,
    dialogTitle: String,
    dialogChild: React.ReactChild
}

class Wrapper extends React.Component<Props, State> {
    state = {
        dialog: false,
        dialogTitle: '',
        dialogChild: <div/>
    };

    private eventSubscription: Subscription;

    componentDidMount() {
        document.onkeydown = (e: KeyboardEvent) =>
            EventBus.post(new KeyDownEvent(e.keyCode));
        window.onscroll = () => {
            const top = this.scrollTop();
            EventBus.post(
                new DocumentScrollEvent(
                    top,
                    document.body.clientHeight - top - window.innerHeight
                ),
            );
        };

        this.eventSubscription = EventBus
            .toObservable()
            .subscribe((event: Event) => {
                    if (event instanceof KeyDownEvent &&
                        event.keyCode === 27) {
                        this.setState({ dialog: false })
                    } else if (event instanceof AlertEvent) {
                        this.setState({
                            dialog: true,
                            dialogTitle: event.title,
                            dialogChild: event.component
                        })
                    }
                }
            )
    }

    componentWillUnmount() {
        document.onkeydown = null;
    }

    componentDidUpdate(props: RouteComponentProps<any>) {
        if (this.props.location.pathname !== props.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        if (this.state.dialog) {
            document.body.className = commonStyles['fixed'];
        } else {
            document.body.className = '';
        }

        return (
            <>
                { this.props.children }
                {
                    this.state.dialog ?
                        <DialogBase
                            title={ this.state.dialogTitle }
                            onCancel={ () => this.setState({ dialog: false }) }>
                            { this.state.dialogChild }
                        </DialogBase> :
                        null
                }
            </>
        )
    }

    private scrollTop = () => (
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0
    );
}

export const Container = withRouter<Props>(Wrapper);
